import { visit } from 'unist-util-visit'

export const customDirective = (options = {}) => {
  const processDirective = (node, index, parent) => {
    const { value } = node
    const match = value.match(/{\.(.*?)}/)

    if (match) {
      const className = match[1]

      // Remove the directive from the text node
      node.value = value.replace(match[0], '').trim()

      // Find the target element to apply the class
      const targetElement =
        parent.children.length > 1
          ? parent
          : parent.children
              .slice(0, index)
              .reverse()
              .find((child) => child.type !== 'paragraph') || parent

      // Add the class to the target element
      if (!targetElement.data) {
        targetElement.data = { hProperties: { className } }
      } else if (!targetElement.data.hProperties) {
        targetElement.data.hProperties = { className }
      } else {
        targetElement.data.hProperties.className = className
      }
    }
  }

  return (tree) => visit(tree, 'text', processDirective)
}
