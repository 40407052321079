import React from 'react'
import { Link, useLocation, useRoutes } from 'react-router-dom'
import styles from './Breadcrumbs.module.scss'
import { useTranslation } from 'react-i18next'
const Breadcrumbs = () => {
  const { t } = useTranslation()
  const location = useLocation()
  const routes = useRoutes([
    { path: '/', element: <></> },

    { path: '/servizi', element: <></> },

    { path: '/servizi/rebirthing', element: <></> },

    { path: '/servizi/tetha-healing', element: <></> },

    { path: '/servizi/massaggio-olistico', element: <></> },

    { path: '/formazione', element: <></> },

    { path: '/formazione/personal-trainer-olistico', element: <></> },

    { path: '/formazione/seminario-olistico', element: <></> },

    { path: '/formazione/corso-rebirthing', element: <></> },

    { path: '/formazione/corso-autostima', element: <></> },

    { path: '/formazione/corso-gravidanza', element: <></> },

    { path: '/formazione/corso-ragazzi', element: <></> },

    { path: '/vacanze-benessere', element: <></> },

    { path: '/vacanze-benessere/maya', element: <></> },

    { path: '/vacanze-benessere/nativi-americani', element: <></> },

    { path: '/vacanze-benessere/ritiro-benessere', element: <></> },

    { path: '/contatto', element: <></> },
  ])

  if (!routes) return null

  const pathnames = location.pathname.split('/').filter((x) => x)

  return (
    <nav aria-label="breadcrumb" className={styles.breadcrumbs}>
      <ol>
        {location.pathname !== '/' && (
          <li>
            <Link to="/">{t('home')}</Link>
          </li>
        )}
        {pathnames.map((value, index) => {
          const isLast = index === pathnames.length - 1
          const path = `/${pathnames.slice(0, index + 1).join('/')}`

          return (
            <li key={index}>
              {isLast ? (
                <span>{t(value)}</span>
              ) : (
                <Link to={path}>{t(value)}</Link>
              )}
            </li>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs
