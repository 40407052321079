import { PageTheme } from 'components/types/common'
import { useEffect } from 'react'

export const useFavicon = (theme: PageTheme) => {
  useEffect(() => {
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = `/assets/images/favicons/${theme}/favicon.png`
    document.getElementsByTagName('head')[0].appendChild(link)
  }, [theme])
}
