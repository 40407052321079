import React, { useCallback } from 'react'
import { useLocation } from 'react-router-dom'
import styles from './Drawer.module.scss'
import {
  Drawer as MuiDrawer,
  List,
  ListItemText,
  Divider,
  ListItemButton,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useContent } from 'content/ContentContext'
import parse, { domToReact, Element } from 'html-react-parser'
import { Link } from 'react-router-dom'

export interface DrawerProps {
  drawerOpen: boolean
  onClick: () => void
}

export const Drawer = ({ drawerOpen, onClick }: DrawerProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  //const pathnames = location.pathname.split('/')
  //   const parsedMarkdown = useContent(pathnames[pathnames.length - 1])

  //   const toc = parsedMarkdown?.toc
  //     ? parse(parsedMarkdown.toc, {
  //         replace: (domNode) => {
  //           if (
  //             domNode instanceof Element &&
  //             domNode.type === 'tag' &&
  //             domNode.name === 'a' &&
  //             domNode.attribs &&
  //             domNode.attribs.href
  //           ) {
  //             return (
  //               <ListItemButton
  //                 to={domNode.attribs.href}
  //                 component={Link}
  //                 onClick={onClick}
  //               >
  //                 <ListItemText primary={domToReact(domNode.children)} />
  //               </ListItemButton>
  //             )
  //           }
  //           return domNode
  //         },
  //       })
  //     : null

  return (
    <MuiDrawer
      anchor="left"
      open={drawerOpen}
      onClose={onClick}
      className={styles.drawer}
    >
      <List>
        <ListItemButton component={Link} to="/" onClick={onClick}>
          <ListItemText primary={t('home')} />
        </ListItemButton>
        <ListItemButton component={Link} to="/servizi" onClick={onClick}>
          <ListItemText primary={t('servizi')} />
        </ListItemButton>
        {location.pathname.startsWith('/servizi') && (
          <>
            <ListItemButton
              component={Link}
              to="/servizi/rebirthing"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('rebirthing')} />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/servizi/tetha-healing"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('tetha-healing')} />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/servizi/massaggio-olistico"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('massaggio-olistico')} />
            </ListItemButton>
          </>
        )}
        <ListItemButton component={Link} to="/formazione" onClick={onClick}>
          <ListItemText primary={t('formazione')} />
        </ListItemButton>
        {location.pathname.startsWith('/formazione') && (
          <>
            <ListItemButton
              component={Link}
              to="/formazione/personal-trainer-olistico"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('personal-trainer-olistico')} />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/formazione/seminario-olistico"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('seminario-olistico')} />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/formazione/corso-rebirthing"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('corso-rebirthing')} />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/formazione/corso-autostima"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('corso-autostima')} />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/formazione/corso-gravidanza"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('corso-gravidanza')} />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/formazione/corso-ragazzi"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('corso-ragazzi')} />
            </ListItemButton>
          </>
        )}
        <ListItemButton
          component={Link}
          to="/vacanze-benessere"
          onClick={onClick}
        >
          <ListItemText primary={t('vacanze-benessere')} />
        </ListItemButton>
        {location.pathname.startsWith('/vacanze-benessere') && (
          <>
            <ListItemButton
              component={Link}
              to="/vacanze-benessere/maya"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('maya')} />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/vacanze-benessere/nativi-americani"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('nativi-americani')} />
            </ListItemButton>
            <ListItemButton
              component={Link}
              to="/vacanze-benessere/ritiro-benessere"
              onClick={onClick}
              sx={{
                marginLeft: '0.5rem',
                ':before': { content: "'•'", paddingRight: '0.5rem' },
              }}
            >
              <ListItemText primary={t('ritiro-benessere')} />
            </ListItemButton>
          </>
        )}
        <ListItemButton component={Link} to="/contatto" onClick={onClick}>
          <ListItemText primary={t('contatto')} />
        </ListItemButton>
      </List>
      {/* {toc != null && (
        <>
          <Divider />
          <List>{toc}</List>
        </>
      )} */}
    </MuiDrawer>
  )
}

function smoothScroll(targetId) {
  const targetElement = document.getElementById(targetId)

  if (targetElement) {
    targetElement.scrollIntoView({
      behavior: 'smooth',
    })
  }
}
