import React from 'react'
import styles from './PreloadImages.module.scss'
import classNames from 'classnames'

const PreloadImages = () => {
  return (
    <div className={styles.preloadImages}>
      <div className={classNames(styles.preloadImage, styles.air)} />
      <div className={classNames(styles.preloadImage, styles.water)} />
      <div className={classNames(styles.preloadImage, styles.fire)} />
      <div className={classNames(styles.preloadImage, styles.earth)} />
      <div className={classNames(styles.preloadImage, styles.ether)} />

      <div className={classNames(styles.preloadImage, styles.airLogo)} />
      <div className={classNames(styles.preloadImage, styles.waterLogo)} />
      <div className={classNames(styles.preloadImage, styles.fireLogo)} />
      <div className={classNames(styles.preloadImage, styles.earthLogo)} />
      <div className={classNames(styles.preloadImage, styles.etherLogo)} />
    </div>
  )
}

export default PreloadImages
