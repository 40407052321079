export async function fetchUrls(language: string) {
  const GITHUB_API_URL =
    'https://api.github.com/repos/lorenabenvenuti/la-via-del-respiro-content/contents'
  const url = `${GITHUB_API_URL}/${language}/markdown`
  const accessToken = process.env.REACT_APP_GITHUB_TOKEN

  async function fetchFiles(folderUrl: string): Promise<string[]> {
    const response = await fetch(folderUrl, {
      headers: {
        Authorization: `token ${accessToken}`,
      },
    })

    if (!response.ok) {
      throw new Error(`Failed to fetch ${folderUrl}`)
    }

    const files = await response.json()
    const urls: string[] = []

    for (const file of files) {
      if (file.type === 'dir') {
        const subFolderUrls = await fetchFiles(file.url)
        urls.push(...subFolderUrls)
      } else if (file.name.endsWith('.md')) {
        urls.push(file.download_url)
      }
    }

    return urls
  }

  try {
    const markdownUrls = await fetchFiles(url)
    return markdownUrls
  } catch (error) {
    console.error(`Error fetching markdown files: ${error}`)
    return []
  }
}

export async function fetchMarkdown(fileUrl: string) {
  return fetch(fileUrl)
    .then((response) => {
      if (response.ok) {
        return response.text()
      } else {
        throw new Error(`Failed to fetch ${fileUrl}`)
      }
    })
    .catch((error) => {
      console.error(`Error fetching markdown: ${error}`)
      return ''
    })
}
