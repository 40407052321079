import classNames from 'classnames'
import { ClassProp } from 'components/types/common'
import React from 'react'
import styles from './Footer.module.scss'

export const Footer = ({ className }: ClassProp) => {
  return (
    <div className={classNames(styles.footer, className)}>
      Copyright © 2009-{`${new Date().getFullYear()}`} Lorena Benvenuti
    </div>
  )
}
