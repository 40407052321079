import React, { useCallback, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import { Page } from 'components/Page/Page'
import { Drawer } from 'components/Drawer/Drawer'

function App() {
  const [drawerOpen, setDrawerOpen] = useState(false)
  const toggleDrawer = useCallback(() => {
    setDrawerOpen(!drawerOpen)
  }, [drawerOpen])
  return (
    <>
      <Drawer drawerOpen={drawerOpen} onClick={toggleDrawer} />
      <Routes>
        <Route
          path="*"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'air'}
              namespace={'home'}
            />
          }
        />

        <Route
          path="/servizi/*"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'fire'}
              namespace={'servizi'}
            />
          }
        />
        <Route
          path="/servizi/rebirthing"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'fire'}
              namespace={'rebirthing'}
            />
          }
        />
        <Route
          path="/servizi/tetha-healing"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'fire'}
              namespace={'tetha-healing'}
            />
          }
        />
        <Route
          path="/servizi/massaggio-olistico"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'fire'}
              namespace={'massaggio-olistico'}
            />
          }
        />

        <Route
          path="/formazione/*"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'earth'}
              namespace={'formazione'}
            />
          }
        />
        <Route
          path="/formazione/personal-trainer-olistico"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'earth'}
              namespace={'personal-trainer-olistico'}
            />
          }
        />
        <Route
          path="/formazione/seminario-olistico"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'earth'}
              namespace={'seminario-olistico'}
            />
          }
        />
        <Route
          path="/formazione/corso-rebirthing"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'earth'}
              namespace={'corso-rebirthing'}
            />
          }
        />
        <Route
          path="/formazione/corso-autostima"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'earth'}
              namespace={'corso-autostima'}
            />
          }
        />
        <Route
          path="/formazione/corso-gravidanza"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'earth'}
              namespace={'corso-gravidanza'}
            />
          }
        />
        <Route
          path="/formazione/corso-ragazzi"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'earth'}
              namespace={'corso-ragazzi'}
            />
          }
        />

        <Route
          path="/vacanze-benessere/*"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'water'}
              namespace={'vacanze-benessere'}
            />
          }
        />
        <Route
          path="/vacanze-benessere/maya"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'water'}
              namespace={'maya'}
            />
          }
        />
        <Route
          path="/vacanze-benessere/nativi-americani"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'water'}
              namespace={'nativi-americani'}
            />
          }
        />
        <Route
          path="/vacanze-benessere/ritiro-benessere"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'water'}
              namespace={'ritiro-benessere'}
            />
          }
        />

        <Route
          path="/contatto/*"
          element={
            <Page
              drawerOpen={drawerOpen}
              onClick={toggleDrawer}
              className={'ether'}
              namespace={'contatto'}
            />
          }
        />
      </Routes>
    </>
  )
}

export default App
