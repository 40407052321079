import classNames from 'classnames'
import { ClassProp } from 'components/types/common'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styles from './Navigation.module.scss'
import { useTranslation } from 'react-i18next'
import { IconButton } from '@mui/material'
import { Menu } from '@mui/icons-material'
interface NavigationProp extends ClassProp {
  onClickDrawerMenuButton: () => void
}

export const Navigation = ({
  className,
  onClickDrawerMenuButton,
}: NavigationProp) => {
  const { t } = useTranslation()

  return (
    <nav className={classNames(styles.navigation, className)}>
      <IconButton
        color="inherit"
        aria-label="menu"
        onClick={onClickDrawerMenuButton}
        sx={{ display: { xs: 'block', sm: 'none' }, marginLeft: '1rem' }}
      >
        <Menu className={styles.drawer} />
      </IconButton>
      <ul>
        <li>
          <NavLink
            to="/"
            className={({ isActive }) =>
              classNames(styles.navLink, isActive && styles.selected)
            }
          >
            {t('home')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/servizi"
            className={({ isActive }) =>
              classNames(styles.navLink, isActive && styles.selected)
            }
          >
            {t('servizi')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/formazione"
            className={({ isActive }) =>
              classNames(styles.navLink, isActive && styles.selected)
            }
          >
            {t('formazione')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/vacanze-benessere"
            className={({ isActive }) =>
              classNames(styles.navLink, isActive && styles.selected)
            }
          >
            {t('vacanze-benessere')}
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contatto"
            className={({ isActive }) =>
              classNames(styles.navLink, isActive && styles.selected)
            }
          >
            {t('contatto')}
          </NavLink>
        </li>
      </ul>
    </nav>
  )
}
