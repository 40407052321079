import classNames from 'classnames'
import { Content } from 'components/Content/Content'
import { Footer } from 'components/Footer/Footer'
import { Header } from 'components/Header/Header'
import { Navigation } from 'components/Navigation/Navigation'
import { ClassProp, NamespaceProp, PageTheme } from 'components/types/common'
import React, { useEffect } from 'react'
import styles from './Page.module.scss'
import { useFavicon } from 'hooks/useFavicon'
import { DrawerProps } from 'components/Drawer/Drawer'

export const Page = ({
  namespace,
  className,
  onClick,
}: NamespaceProp & ClassProp & DrawerProps) => {
  useFavicon(className as PageTheme)
  useEffect(() => {
    if (className) {
      document.body.classList.add(className)

      return () => {
        document.body.classList.remove(className)
      }
    }
  }, [className])
  return (
    <div className={classNames(styles.page, className)}>
      <div className={styles.content}>
        <Header className={className} />
        <Navigation onClickDrawerMenuButton={onClick} className={className} />
        <Content className={className} namespace={namespace} />
        <Footer className={className} />
      </div>
    </div>
  )
}
