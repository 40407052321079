import React, { useLayoutEffect, useRef, useState } from 'react'
import classNames from 'classnames'
import styles from './Content.module.scss'
import { ClassProp, NamespaceProp } from 'components/types/common'
import { useContent } from 'content/ContentContext'
import ReactMarkdown from 'react-markdown'
import parse, { domToReact, Element } from 'html-react-parser'
import remarkSlug from 'remark-slug'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { Link } from 'react-scroll'
import { Link as RouterLink } from 'react-router-dom'
import remarkDirective from 'remark-directive'
import { customDirective } from 'content/customDirective'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

export const Content = ({
  className,
  namespace,
}: ClassProp & NamespaceProp) => {
  const [shouldGenerateTOC, setShouldGenerateTOC] = useState(false)
  const contentRef = useRef<HTMLDivElement>(null)

  const parsedMarkdown = useContent(namespace)

  const toc = parsedMarkdown?.toc
    ? parse(parsedMarkdown.toc, {
        replace: (domNode) => {
          if (
            domNode instanceof Element &&
            domNode.type === 'tag' &&
            domNode.name === 'a' &&
            domNode.attribs &&
            domNode.attribs.href
          ) {
            return (
              <Link
                className={styles.link}
                smooth
                offset={-15}
                to={domNode.attribs.href.substring(1)}
              >
                {domToReact(domNode.children)}
              </Link>
            )
          }
          return domNode
        },
      })
    : null

  useLayoutEffect(() => {
    if (contentRef.current) {
      const contentHeight = contentRef.current?.scrollHeight
      const windowHeight = window.innerHeight

      if (contentHeight && windowHeight) {
        setShouldGenerateTOC(toc != null && contentHeight > windowHeight * 1.5)
      }
    }
  }, [parsedMarkdown?.content, toc])

  const fileExtensionRegex = /\.[0-9a-z]+$/i

  return (
    <div className={classNames(styles.content, className)}>
      {parsedMarkdown && (
        <>
          {shouldGenerateTOC && <nav className={styles.toc}>{toc}</nav>}
          <article
            ref={contentRef}
            className={classNames(styles.contentBody, toc && styles.hasToc)}
          >
            <Breadcrumbs />
            <ReactMarkdown
              remarkPlugins={[
                remarkGfm,
                remarkSlug,
                remarkDirective,
                customDirective,
              ]}
              rehypePlugins={[rehypeRaw]}
              components={{
                a: ({ href, children, ...props }) => {
                  if (
                    href &&
                    !href.startsWith('http') &&
                    !href.startsWith('//') &&
                    !fileExtensionRegex.test(href)
                  ) {
                    return href.startsWith('#') ? (
                      <Link
                        className={styles.link}
                        smooth
                        offset={-15}
                        to={href.substring(1)}
                      >
                        {children}
                      </Link>
                    ) : (
                      <RouterLink to={href} {...props}>
                        {children}
                      </RouterLink>
                    )
                  } else {
                    return (
                      <a href={href} {...props}>
                        {children}
                      </a>
                    )
                  }
                },
                img: ({ src, ...props }) => {
                  return (
                    <img
                      src={`https://raw.githubusercontent.com/lorenabenvenuti/la-via-del-respiro-content/main/${src}`}
                      {...props}
                    />
                  )
                },
              }}
            >
              {parsedMarkdown.content}
            </ReactMarkdown>
          </article>
        </>
      )}
    </div>
  )
}
