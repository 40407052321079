import classNames from 'classnames'
import React from 'react'
import styles from './Loading.module.scss'

import { ReactComponent as Lotus } from '../../assets/images/lotus.svg'

export const Loading = () => {
  return (
    <div className={classNames(styles.loading, 'air')}>
      <div className={styles.container}>
        <Lotus title="Caricando..." className={styles.lotus} />
        <div className={styles.label}>Caricando...</div>
      </div>
    </div>
  )
}
