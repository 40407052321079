import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { fetchMarkdown, fetchUrls } from './contentAPI'
import { Loading } from 'components/Loading/Loading'
import { extractHeadings, generateTOC } from './tocGenerator'
import { ParsedMarkdown } from 'components/types/common'
import PreloadImages from './PreloadImages/PreloadImages'

const ContentContext = React.createContext<Map<string, ParsedMarkdown>>(
  new Map()
)

export const ContentProvider = ({ children }: PropsWithChildren) => {
  const { i18n } = useTranslation()
  const [content, setContent] = useState<Map<string, ParsedMarkdown>>(new Map())
  const [isLoading, setIsLoading] = useState<boolean>(true)

  useEffect(() => {
    const fetchContent = async (language: string) => {
      try {
        setIsLoading(true)
        const urls = await fetchUrls(language)
        const fetchPromises = urls.map((url: string) => fetchMarkdown(url))
        const results = await Promise.all(fetchPromises)
        const contentMap = new Map<string, ParsedMarkdown>()
        for (let i = 0; i < urls.length; i++) {
          const md = results[i]
          const fileName = urls[i].split('/').pop()?.split('.')[0] || ''
          const headings = await extractHeadings(md)
          const toc = headings && generateTOC(headings)
          contentMap.set(fileName, { toc, content: results[i] })
        }
        setContent(contentMap)
      } catch (error) {
        console.error('Error fetching content:', error)
      } finally {
        setIsLoading(false)
      }
    }

    fetchContent(i18n.language)
  }, [i18n.language])

  return (
    <>
      <PreloadImages />
      {isLoading ? (
        <Loading />
      ) : (
        <ContentContext.Provider value={content}>
          {children}
        </ContentContext.Provider>
      )}
    </>
  )
}

export const useContent = (namespace: string) => {
  const context = React.useContext(ContentContext)
  const result = context.get(namespace)
  return result || null
}
