import markdown from 'remark-parse'
import remark2rehype from 'remark-rehype'
import html from 'rehype-stringify'
import slug from 'remark-slug'
import toc from 'remark-toc'
import { Processor, unified } from 'unified'

const processor: Processor = unified()
  .use(markdown)
  .use(slug)
  .use(toc)
  .use(remark2rehype)
  .use(html)

export async function extractHeadings(
  markdownText: string
): Promise<string | null> {
  try {
    const result = await processor.process(markdownText)
    return result.toString()
  } catch (error) {
    console.error('Error processing markdown:', error)
    return null
  }
}

export function generateTOC(headings: string) {
  const parser = new DOMParser()
  const document = parser.parseFromString(headings, 'text/html')
  const tags = document.querySelectorAll('h1, h2, h3, h4, h5, h6')

  if (tags == null || tags.length <= 1) return null

  const list = document.createElement('ul')

  let currentList = list
  let currentLevel = 1

  Array.from(tags).forEach((heading) => {
    const level = parseInt(heading.tagName.slice(1))

    if (level > currentLevel) {
      while (level > currentLevel) {
        const nestedList = document.createElement('ul')
        currentList.lastElementChild?.appendChild(nestedList)
        currentList = nestedList
        currentLevel++
      }
    } else if (level < currentLevel) {
      while (level < currentLevel) {
        currentList =
          (currentList.parentElement?.parentElement as HTMLUListElement) ??
          currentList
        currentLevel--
      }
    }

    const listItem = document.createElement('li')
    const link = document.createElement('a')
    link.href = `#${heading.getAttribute('id')}`
    link.textContent = heading.textContent

    listItem.appendChild(link)
    currentList.appendChild(listItem)
  })

  return currentList.outerHTML
}
