import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import HttpBackend from 'i18next-http-backend'

const REPO_URL =
  'https://raw.githubusercontent.com/lorenabenvenuti/la-via-del-respiro-content/main/'

i18n
  .use(HttpBackend)
  .use(initReactI18next)
  .init({
    ns: ['navigazione'],
    defaultNS: 'navigazione',
    supportedLngs: ['en', 'it', 'es', 'fr'],
    fallbackLng: 'it',
    debug: process.env.NODE_ENV === 'development',
    backend: {
      loadPath: REPO_URL + '{{lng}}/json/{{ns}}.json',
    },
    react: {
      useSuspense: false,
    },
  })

export default i18n
